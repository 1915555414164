<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-show="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div
      class="qinfo"
      v-show="loading == false && hasQuestionnaire"
      :style="
        questionnaire.backgroundImageUrl &&
        questionnaire.backgroundImageUrl != ''
          ? 'background: url(' +
            questionnaire.backgroundImageUrl +
            '); background-size: contain;'
          : ''
      "
    >
      <!-- <div v-show="loading == false" > -->
      <div v-if="hasQuestionnaire">
        <div class="acontent" v-html="questionnaire.detailsHtml"></div>

        <div
          v-for="(item, index) in questionnaire.questionList"
          :key="item.id.toString()"
          class="questionList"
          :style="
            item.haserror === true
              ? 'border: 2px solid red;'
              : 'border: 2px solid rgba(255,255,255,0);'
          "
          :id="item.id.toString()"
        >
          <div class="question">
            <h2>
              {{ index + 1 }}、{{ item.title }}
              {{
                item.typeId == "5" && item.answerText > 0
                  ? "(" + item.answerText + "分)"
                  : ""
              }}
            </h2>
             <h4>
             {{ item.summary }}
             
            </h4>
          </div>
          <div v-if="item.typeId == 1" class="option">
            <van-radio-group
              v-model="item.checked"
              @change="checkquestion(index)"
            >
              <van-radio
                :name="option.id.toString()"
                v-for="(option, oindex) in item.optionList"
                :key="option.id.toString()"
                >{{ GetOptionTitle(option.title, oindex) }}
                <van-image
                  v-if="
                    option.imageUrl != undefined && option.imageUrl.length > 10
                  "
                  :src="option.imageUrl"
                >
                  <template v-slot:error>图片加载失败</template>
                </van-image>
              </van-radio>
            </van-radio-group>
          </div>
          <div v-if="item.typeId == 2" class="option">
            <van-checkbox-group
              v-model="item.checked"
              @change="checkquestion(index)"
            >
              <van-checkbox
                shape="square"
                :name="option.id.toString()"
                v-for="(option, oindex) in item.optionList"
                :key="option.id.toString()"
                >{{ GetOptionTitle(option.title, oindex) }}
                <van-image
                  v-if="
                    option.imageUrl != undefined && option.imageUrl.length > 10
                  "
                  :src="option.imageUrl"
                >
                  <template v-slot:error>图片加载失败</template>
                </van-image>
              </van-checkbox>
            </van-checkbox-group>
          </div>
          <!-- 文本 -->
          <div v-if="item.typeId == 3" class="questionInput">
            <van-field
              size="large"
              v-model="item.answerText"
              :disabled="submitDisabled"
              :error-message="item.errormessage"
               @blur="checkInput(index)"
            />
          </div>
          <!-- 整数 -->
          <div v-if="item.typeId == 32" class="questionInput">
            <van-field
              size="large"
              type="digit"
              v-model="item.answerText"
              :disabled="submitDisabled"
              :error-message="item.errormessage"
               @blur="checkInput(index)"
            />
          </div>
          <!-- 数字 -->
          <div v-if="item.typeId == 33" class="questionInput">
            <van-field
              size="large"
              type="number"
              v-model="item.answerText"
              :disabled="submitDisabled"
              :error-message="item.errormessage"
               @blur="checkInput(index)"
            />
          </div>
          <!-- 手机号 -->
          <div v-if="item.typeId == 31" class="questionInput">
            <van-field
              size="large"
              type="tel"
              maxlength="11"
              v-model="item.answerText"
              :disabled="submitDisabled"
              :error-message="item.errormessage"
               @blur="checkInputPhone(index)"
            />
          </div>
          <!-- 备注 -->
          <div v-if="item.typeId == 34" class="questionInput">
            <van-field
              v-model="item.answerText"
              :disabled="submitDisabled"
              :error-message="item.errormessage"
               @blur="checkInput(index)"
              rows="2"
              autosize
              type="textarea"
            />
            <!-- <textarea
              class="texta"
              rows="2"
              autosize
              v-model="item.answerText"
              :disabled="submitDisabled"
              :error-message="item.errormessage"
              @input="checkInput(index)"
            ></textarea> -->
          </div>
          <!-- 日期 -->
          <div v-if="item.typeId == 4" class="questionInput">
            <van-cell
              color="#646566"
              title="日期"
              :value="item.answerText"
              @click="item.show = true"
            />
            <van-calendar
              v-model="item.show"
              @confirm="onConfirm($event, index)"
            />
          </div>
          <!-- 评分 -->
          <div v-if="item.typeId == 5" class="option">
            <van-rate
              v-model="item.answerText"
              @change="onChange($event, index)"
              count="10"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
              size="25px"
            />
          </div>
        </div>
 
        <div style="margin: 0 10px 10px 20px">
          <h3  style=""><a href="tel:4008908799" style="color: black;"><i class="phone2"></i></a></h3>
        
        </div>
        <div
          v-if="questionnaire.summary != undefined"
          style="margin: 0 10px 20px 20px"
        >
          <h3>{{ questionnaire.summary }}</h3>
        </div>
        <div
          class="questionInput"
          v-if="HasUserName"
          :style="
            usernameerror != ''
              ? 'border: 2px solid red;'
              : 'border: 2px solid rgba(255,255,255,0);'
          "
        >
          <van-field
            v-model="answer.userName"
            size="large"
            label="姓名"
            placeholder="请输入姓名"
            maxlength="15"
            :disabled="submitDisabled"
            :error-message="usernameerror"
            @input="inputUserName"
          />
        </div>
        <div
          class="questionInput"
          v-if="HasPhone"
          :style="
            phoneerror != ''
              ? 'border: 2px solid red;'
              : 'border: 2px solid rgba(255,255,255,0);'
          "
        >
          <van-field
            size="large"
            type="tel"
            v-model="answer.phone"
            :disabled="submitDisabled"
            label="手机号"
            maxlength="11"
            placeholder="请输入手机号"
            :error-message="phoneerror"
            @input="inputPhone"
          />
        </div>

        <div
          v-if="HasAddress"
          class="questionInput"
          :style="
            addresserror != ''
              ? 'border: 2px solid red;'
              : 'border: 2px solid rgba(255,255,255,0);'
          "
        >
          <van-field
            size="large"
            v-model="answer.address"
            :disabled="submitDisabled"
            label="地址"
            placeholder="请输入地址"
            :error-message="addresserror"
            @input="inputAddress"
          />
        </div>

        <div class="submit">
          <van-button
            v-if="questionnaire.showType == 1 || questionnaire.showType == 500"
            round
            block
            :disabled="submitDisabled"
            type="primary"
            native-type="submit"
            @click="onsubmit()"
          >
            立即抢购
          </van-button>
          <div
            class="submitSuccess"
            id="posterImage"
            v-show="questionnaire.showType == 1"
          >
            {{ showMessage }}
          </div>

          <div class="submitError" v-show="questionnaire.showType > 1">
            {{ showMessage }}
          </div>
        </div>

        <div v-if="poster != undefined && poster.length > 0">
          <van-notice-bar
            color="#1989fa"
            background="#ecf9ff"
            left-icon="info-o"
          >
            长按图片，转发给朋友
          </van-notice-bar>
          <!-- 轮播图 -->
          <van-swipe class="my-swipe" :autoplay="3000">
            <van-swipe-item v-for="(image, index) in poster" :key="index">
              <img v-lazy="image.qrPrviewImage" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>

    <div v-if="loading == false && !hasQuestionnaire" style="padding-top: 15px">
      <!-- <div style="color: white; text-align: center">
        {{ showTypeInfo }}
      </div> -->
      <div class="submitError">
        {{ showMessage }}
      </div>
      <div v-if="poster != undefined && poster.length > 0">
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
          长按图片，转发给朋友
        </van-notice-bar>
        <!-- 轮播图 -->
        <van-swipe class="my-swipe" :autoplay="3000">
          <van-swipe-item v-for="(image, index) in poster" :key="index">
            <img v-lazy="image.qrPrviewImage" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast,Dialog } from "vant";
import {
  getDevice,
  getQuestionnaire,
  AddAnswer,
  getPoster,
} from "@/api/question";

export default {
  props: {
    HasAddress: {
      type: Boolean,
      default: false,
    },
    HasPhone: {
      type: Boolean,
      default: true,
    },
    HasUserName: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: true,
      questionnaire: {},
      hasQuestionnaire: true,
      phoneerror: "",
      addresserror: "",
      usernameerror: "",
      submitDisabled: false,
      showMessage: "",
      showTypeInfo: "",
      answer: {
        questionnaireId: "",
        parentDeviceId: "00000000-0000-0000-0000-000000000000",
        sourceType: 1,
        deviceId: "",
        userCode: "",
        userName: "",
        phone: "",
        position: "",
        province: "",
        city: "",
        county: "",
        address: "",
        questionList: [],
      },
      poster: [],
    };
  },

  created() {
    this.initData();
  },

  methods: {
    async initData() {
      let questionnaireId = this.$route.query.q;
      let userCode = this.$route.query.u;
      let deviceId = localStorage.getItem("deviceId"+questionnaireId);
      if (deviceId == undefined || deviceId == "" || deviceId == null) {
        var dresult = await getDevice(questionnaireId, userCode);
        localStorage.setItem("deviceId"+questionnaireId, dresult.data.data);
        deviceId = dresult.data.data;
      }

      this.answer.deviceId = deviceId;
      this.answer.questionnaireId = questionnaireId;
      this.answer.userCode = userCode;
      let parentDeviceId = this.$route.query.d;
      if (
        parentDeviceId == undefined ||
        parentDeviceId == "" ||
        parentDeviceId == null
      ) {
        this.answer.parentDeviceId = "00000000-0000-0000-0000-000000000000";
      } else {
        this.answer.parentDeviceId = parentDeviceId;
      }

      var quResult = await getQuestionnaire(questionnaireId, deviceId);

      if (quResult.data.success) {
        var myquestionnaire = quResult.data.data;
        for (
          let index = 0;
          index < myquestionnaire.questionList.length;
          index++
        ) {
          //初始化附加属性值
          myquestionnaire.questionList[index].haserror = false;
          myquestionnaire.questionList[index].errormessage = "";
          if (myquestionnaire.questionList[index].typeId == 5) {
            myquestionnaire.questionList[index].answerText = 0;
          } else {
            myquestionnaire.questionList[index].answerText = "";
          }

          myquestionnaire.questionList[index].show = false;
        }
        this.questionnaire = myquestionnaire;
      } else {
        this.hasQuestionnaire = false;
        this.showMessage = quResult.data.errorMessage;
        Toast(quResult.data.errorMessage);
      }
      if (this.questionnaire.showType > 1) {
        this.submitDisabled = true;
        this.hasQuestionnaire = false;
      }
      if (this.questionnaire.showType == 2) {
        this.showMessage = this.questionnaire.title + "抢购活动未开始，敬请期待！";
      }
      if (this.questionnaire.showType == 3) {
        this.showMessage =
          this.questionnaire.title + "抢购活动已结束，期待您下次参与!";
      }
      if (this.questionnaire.showType == 4) {
        this.showMessage = "您已完成本次抢购，感谢您的帮助与支持!";
        this.showTypeInfo = this.questionnaire.showTypeInfo;
      }
      if (this.questionnaire.showType == 5) {
        this.showMessage = "您已完成本次抢购，感谢您的帮助与支持!!";
        this.showTypeInfo = this.questionnaire.showTypeInfo;
      }
      if (this.questionnaire.showType == 6) {
        this.showMessage = "您已完成本次抢购，感谢您的帮助与支持!!!";
        this.showTypeInfo = this.questionnaire.showTypeInfo;
      }
      if (
        this.questionnaire.showType >= 4 &&
        this.questionnaire.showType <= 6
      ) {
        await this.getMyPoster();
      }

      this.loading = false;
    },
     onChange(value, index) {
      Toast("评分：" + value + "分");
      this.questionnaire.questionList[index].haserror = false;
      this.questionnaire.questionList[index].answerText = value;
    },
    checkquestion(index) {
      var that = this;
      that.questionnaire.questionList[index].haserror = false;
    },
    checkInput(index) {
      if (
        (this.questionnaire.questionList[index].typeId == 3 ||
          (this.questionnaire.questionList[index].typeId >= 31 &&
            this.questionnaire.questionList[index].typeId <= 39)) &&
        this.questionnaire.questionList[index].isMust
      ) {
        if (
          (this.questionnaire.questionList[index].minLength ||
            this.questionnaire.questionList[index].minLength > 0) &&
          this.questionnaire.questionList[index].answerText.replace(
            /(^\s*)|(\s*$)/g,
            ""
          ).length < this.questionnaire.questionList[index].minLength
        ) {
          this.questionnaire.questionList[index].errormessage =
            "最小长度是" +
            this.questionnaire.questionList[index].minLength +
            "个字符";
          this.questionnaire.questionList[index].haserror = true;
        } 
        else if( this.questionnaire.questionList[index].answerText=="" &&  this.questionnaire.questionList[index].isMust){
           this.questionnaire.questionList[index].haserror = true;
          this.questionnaire.questionList[index].errormessage = "不可为空";
        }
        else
        {
          this.questionnaire.questionList[index].haserror = false;
          this.questionnaire.questionList[index].errormessage = "";
        }
      }
    },
    checkInputPhone(index) {
      if (this.questionnaire.questionList[index].answerText.length < 11) {
        this.questionnaire.questionList[index].errormessage = "";
        return;
      }
      var phonereg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!phonereg.test(this.questionnaire.questionList[index].answerText)) {
        this.questionnaire.questionList[index].errormessage =
          "请填写有效的手机号码";
        this.questionnaire.questionList[index].haserror = true;
      } else {
        this.questionnaire.questionList[index].errormessage = "";
        this.questionnaire.questionList[index].haserror = false;
      }
    },
    async onsubmit() {
      this.submitDisabled = true;
      // var checked = true;
      // var checkeditem;
      var itemerror = "";
      for (
        let index = 0;
        index < this.questionnaire.questionList.length;
        index++
      ) {
        // element是所有信息
        const element = this.questionnaire.questionList[index];
        if (element.typeId == 1 && element.checked == undefined  &&element.isMust) {
          element.haserror = true;
        } else if (
          element.typeId == 2 &&
          (element.checked == undefined || element.checked.length == 0 && element.isMust)
        ) {
          element.haserror = true;
        } else if (
          (element.typeId == 4 ||
            element.typeId == 3 ||
            (element.typeId >= 31 && element.typeId <= 39)) &&
          element.isMust &&
          element.answerText == ""
        ) {
          element.haserror = true;
          this.questionnaire.questionList[index].errormessage == "不可为空"
        } else if (element.typeId == 5 && element.answerText == 0) {
          element.haserror = true;
        }

        if (element.typeId == 31 && element.isMust) {
          if (!phonereg.test(element.answerText)) {
            element.errormessage = "请填写有效的手机号码";
            element.haserror = true;
          } else {
            element.haserror = false;
            element.errormessage = "";
          }
        }

        // str.replace(/(^\s*)|(\s*$)/g, "")
        if (
          (element.typeId == 3 ||
            (element.typeId >= 31 && element.typeId <= 39)) &&
          element.isMust
        ) {
          if (
            (element.minLength || element.minLength > 0) &&
            element.answerText.replace(/(^\s*)|(\s*$)/g, "").length <
              element.minLength
          ) {
            element.errormessage = "最小长度是" + element.minLength + "个字符";
            element.haserror = true;
          } 
        }

      
      }

        for (
        let index = 0;
        index < this.questionnaire.questionList.length;
        index++
      ) {
        // element是所有信息
        const element = this.questionnaire.questionList[index];
          //第一个错误,页面会滚动到
        if ( element.haserror) {
          itemerror =
            index +
            1 +
            "、" +
            element.title +
            (element.typeId <= 2 ? " 请选择" : " 请填写");
          //checked = false;
          Toast(itemerror);
          document.getElementById(element.id).scrollIntoView();
          this.submitDisabled = false;
          return;
        }
      }

      if (this.HasPhone) {
        var phonereg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (!phonereg.test(this.answer.phone)) {
          this.phoneerror = "请填写有效的手机号码";
        } else {
          this.phoneerror = "";
        }
      }

      if (this.HasUserName) {
        if (this.answer.userName == "") {
          this.usernameerror = "请填写姓名";
        } else {
          this.usernameerror = "";
        }
      }
      if (this.HasAddress) {
        if (this.answer.address == "") {
          this.addresserror = "请填写地址";
        } else {
          this.addresserror = "";
        }
      }

      // if (!checked && checkeditem !== undefined) {
      //   Toast(itemerror);
      //   document.getElementById(checkeditem.id).scrollIntoView();
      //   this.submitDisabled = false;
      //   return;
      // }
      if (
        this.phoneerror != "" ||
        this.usernameerror != "" ||
        this.addresserror != ""
      ) {
        this.submitDisabled = false;
        return;
      }
      //抢购答案
      this.answer.questionList = [];
      for (
        let index = 0;
        index < this.questionnaire.questionList.length;
        index++
      ) {
        const question = this.questionnaire.questionList[index];
        var option = {};
        if (question.typeId == 1) {
          option = {
            id: question.id,
            typeId: question.typeId,
            optionValue: question.checked?.toString(),
            answerText: "",
          };
        } else if (question.typeId == 2) {
          option = {
            id: question.id,
            typeId: question.typeId,
            optionValue: question.checked?.toString(),
            answerText: "",
          };
        } else if (question.typeId >= 3 && question.typeId <= 50) {
          option = {
            id: question.id,
            typeId: question.typeId,
            optionValue: "",
            answerText: question.answerText.toString(),
          };
        }
        this.answer.questionList.push(option);
      }
      // var amapInfo = localStorage.getItem("AMapInfo");
      // if (amapInfo == "SUCCESS") {
      //   this.answer.province = localStorage.getItem("AMapInfo_province");
      //   this.answer.city = localStorage.getItem("AMapInfo_city");
      //   this.answer.county = localStorage.getItem("AMapInfo_district");
      //   this.answer.address = localStorage.getItem("AMapInfo_address");
      //   this.answer.position =
      //     localStorage.getItem("AMapInfo_lng") +
      //     "," +
      //     localStorage.getItem("AMapInfo_lat");
      //   if (this.answer.city == "") {
      //     this.answer.city = this.answer.province;
      //   }
      // }

      try {
        var areulst = await AddAnswer(this.answer);
        if (areulst.data.success) {
          //Toast("提交成功,专业客服,稍后联系您 ");
          Dialog.alert({
  title: '提交成功',
  message: '专业客服,稍后联系您',
}).then(() => {
  // on close
});
          this.showMessage = "提交成功,专业客服,稍后联系您 ";
          await this.getMyPoster();
        } else {
          Toast(areulst.data.errorMessage);
          this.submitDisabled = false; //questionnaire.showType
          this.questionnaire.showType = 500;
          this.showMessage = "提交失败";
          if (areulst.data.errorCode == "PhoneError") {
            this.showMessage = areulst.data.errorMessage;
          }
        }
      } catch (error) {
        this.submitDisabled = false;
      }
    },

    async getMyPoster() {
      var presult = await getPoster(
        this.answer.questionnaireId,
        this.answer.userCode,
        this.answer.deviceId
      );
      if (presult.data.success) {
        this.poster = presult.data.data;
        if (this.hasQuestionnaire && this.poster.length > 0) {
          setTimeout(() => {
            document.getElementById("posterImage").scrollIntoView();
          }, 500);
        }
      }
    },
    inputPhone() {
      if (this.answer.phone.length < 11) {
        this.phoneerror = "";
        return;
      }
      var phonereg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!phonereg.test(this.answer.phone)) {
        this.phoneerror = "请填写有效的手机号码";
      } else {
        this.phoneerror = "";
      }
    },
    inputUserName() {
      if (this.answer.userName.length < 1) {
        return;
      }
      if (this.answer.userName == "") {
        this.usernameerror = "请填写姓名";
      } else {
        this.usernameerror = "";
      }
    },
    inputAddress() {
      if (this.answer.address != undefined && this.answer.address.length < 1) {
        return;
      }
      if (this.answer.address == "") {
        this.addresserror = "请填写地址";
      } else {
        this.addresserror = "";
      }
    },

    GetOptionTitle(title, index) {
      var optionIndex = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      return optionIndex[index] + "、" + title;
    },
  },
};
</script>

<style lang="less" scoped>
.phone1{
  height: 145px;
    width: 100%;
    background-color: #fff4f0;
    background-image: none;
    position: relative;
    top: 0;
    left: 0;
   
}
@keyframes pulse {
  0% {
    background-color: #fff;
  }
  100% {
    background-color: #FF4136;
  }
}
.phone2{
  background: url(/image/common_sprite.png) -630px -737px;
    width: 71px;
    height: 71px;
    top: 50%;
    animation: pulse 2s infinite;
   
    display: inline-block;
    right: 25px;
    position: absolute;
   
}
.loading {
  text-align: center;
}
.van-cell{
  background-color: unset;
}
.container {
 margin: 0 auto;
  max-width: 750px;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  //padding: 0px 10px 20px 10px;
  /deep/.van-field__control,
  input,
  textarea {
    border: 1px solid #d8d8d8 !important;
  }
}
.qinfo {
  padding-bottom: 50px;
}
.questionList {
  margin: 0px 10px 20px 10px;
}
.questionInput {
  // height: 75px;
  margin: 0px 10px 5px 10px;
  font-size: 16px;
}
.question {
  margin: 20px 10px 20px 10px;
}

.van-checkbox {
  margin: 10px 0px 10px 5px;
  border: 1px solid #d8d8d8;
  padding: 5px;
}
.van-checkbox[aria-checked="true"] {
  border: 1px solid #2672ff;
  padding: 5px;
}

.van-radio {
  margin: 10px 0px 10px 5px;
  border: 1px solid #d8d8d8;
  padding: 5px;
}
.van-radio[aria-checked="true"] {
  border: 1px solid #2672ff;
  padding: 5px;
}

.submit {
  padding: 30px 20px 10px;
}
.submitInfo {
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}
.submitSuccess {
  color: green;
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}
.submitError {
  color: red;
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}
.posterInfo {
  padding-top: 10px;
  font-size: 15px;
  text-align: center;
}
.acontent {

  padding: 10px;


  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
.my-swipe .van-swipe-item {
  //height: 150px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  //background-color: #39a9ed;
}

.my-swipe img {
  display: inline-block;
  width: 75%;
  height: 10%;
}
</style>